<template>
  <v-select
    v-bind="$attrs"
    v-model="selected"
    :items="products"
    item-text="name"
    label="Product"
    return-object
    outlined
  >
    <template v-slot:item="{ item, on }">
      <v-list-item
        ripple
        @mousedown.prevent
        v-on="on"
      >
        <v-list-item-content>
          <v-list-item-title>
            {{ item.name }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ item.description }}
          </v-list-item-subtitle>
          <v-list-item-subtitle v-if="item.extraDescription">
            {{ item.extraDescription }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>

  </v-select>
</template>

<script>
import axios from "axios";

export default {
  components: {},
  name: "ProductSelect",
  data() {
    return {
      products: [],
      selected: null,
    };
  },
  props: {
    prefix: String,
  },
  created() {
    this.fetchProducts();
  },
  methods: {
    fetchProducts() {
      let url = "/api/" + this.prefix + "/product";
      axios.get(url).then((response) => {
        this.products = response.data.content;
      });
    },
  },
  watch: {
    selected() {
      this.$emit('selected', this.selected)
    }
  }
};
</script>

<style scoped>

</style>
