<template>
  <v-container class="content">
    <v-row>
      <v-col align="center">
        <h1 class="mb-8">Create Order</h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card elevation="3">
          <v-card-title>Items</v-card-title>
          <v-card-text>
            <order-items v-model="orderItems" prefix="retailer"></order-items>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col align="right">
        <v-btn right color="primary" dark @click="submit"
               :loading="loading"
               :disabled="loading">
          Create
          <v-icon right> mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import axios from "axios";
import OrderItems from "@/components/general/OrderItems.vue";

export default {
  components: {OrderItems},
  name: "RetailerOrderCreateView",
  metaInfo: {
    title: "Create Order",
  },
  data() {
    return {
      order: {},
      orderItems: [{
        index: 1
      }],
      loading: false,
    };
  },
  methods: {
    submit() {
      this.loading = true;
      this.order.orderItems = this.orderItems

      axios
          .post("/api/retailer/order", this.order)
          .then(() => {
            this.$store.dispatch('banner/open', {text: 'Created new Order!'})
            this.$router.push({name: 'retailerOrdersList'})
          })
          .finally(() => {
            this.loading = false;
          });
    },
  },
};
</script>

<style scoped>
</style>
