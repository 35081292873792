<template>
  <div>
    <v-row no-gutters v-for="(orderItem, i) in orderItems" :key="orderItem.index">
      <v-col>
        <order-item v-model="orderItems[i]" :mode="mode" @remove="removeItem(i)" :prefix="prefix"></order-item>
      </v-col>
    </v-row>
    <v-row v-if="editMode">
      <v-col>
        <v-btn @click="orderItems.push({index: orderItems[orderItems.length -1].index + 1})" color="green"
               class="white--text">
          <v-icon> mdi-plus</v-icon>
          Add Item
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import OrderItem from "@/components/general/OrderItem.vue";

export default {
  components: {OrderItem},
  name: "OrderItems",
  props: {
    value: Array,
    mode: {
      type: String,
      default: 'edit'
    },
    prefix: String,
  },
  computed: {
    orderItems: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    editMode() {
      return this.mode === 'edit'
    }
  },
  methods: {
    removeItem(index) {
      this.$delete(this.orderItems, index)
    }
  },
};
</script>

<style scoped>
</style>
