<template>
  <div>
    <v-row v-if="editMode">
      <v-col cols="5">
        <product-select @selected="productSelected" :prefix="prefix"></product-select>
      </v-col>
      <v-col cols="2">
        <v-text-field type="number" v-model="orderItem.quantity" outlined label="Quantity"></v-text-field>
      </v-col>
      <v-col cols="1" class="text-center">
        <v-btn icon @click="remove">
          <v-icon large class="mt-3">mdi-trash-can</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="5">
        <v-text-field outlined readonly label="Product" :value="orderItem.product.name"></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-text-field outlined readonly label="Quantity" :value="orderItem.quantity"></v-text-field>
      </v-col>
    </v-row>
  </div>

</template>

<script>
import ProductSelect from "@/components/general/ProductSelect.vue";

export default {
  components: {ProductSelect},
  name: "OrderItem",
  props: {
    value: Object,
    mode: {
      type: String,
      default: 'edit'
    },
    prefix: String,
  },
  computed: {
    orderItem: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    editMode() {
      return this.mode === 'edit'
    }
  },
  methods: {
    productSelected(product) {
      this.$set(this.orderItem, 'product', product)
    },
    remove() {
      this.$emit("remove");
    },
  }
};
</script>

<style scoped>
</style>
